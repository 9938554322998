export const TransactionNotFound = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '45%',
        left: 0,
        right: 0,
        bottom: 0,
        textAlign: 'center',
      }}
    >
      Not Found
    </div>
  );
};
